var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" } },
    [
      _c("title", [_vm._v("site@1x")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.82",
              y: "7.72",
              width: "5.24",
              height: "0.63",
              rx: "0.17",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "9.9",
              y: "7.72",
              width: "4.3",
              height: "4.45",
              rx: "0.17",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.82",
              y: "9.63",
              width: "5.24",
              height: "0.63",
              rx: "0.17",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.82",
              y: "11.54",
              width: "5.24",
              height: "0.63",
              rx: "0.17",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.35,3.91H2.65A.65.65,0,0,0,2,4.56v8.88a.65.65,0,\n        0,0,.65.65h12.7a.65.65,0,0,0,.65-.65V4.56A.65.65,0,0,\n        0,15.35,3.91Zm-8,.69a.54.54,0,0,1,0,1.08.54.54,0,1,1,\n        0-1.08ZM5.4,4.6a.54.54,0,1,1-.54.54A.54.54,0,0,1,5.4,\n        4.6Zm-1.92,0a.54.54,0,1,1-.54.54A.54.54,0,0,1,3.48,\n        4.6Zm11.74,8.71H2.78V6.44H15.22Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }